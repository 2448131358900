import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'src/libs/axios';
import { QueryOptions } from 'src/libs/react-query';
import { Address } from 'viem';
import { useChainId } from 'wagmi';
import { tokenKeys } from './token-keys';

export interface Pair {
  address: Address;
  chain_id: number;
  decimal: number;
  name: string;
  token_id: string;
  total_balance: number;
}

export interface Token {
  token: string;
  address: Address;
  decimal: number;
}

export type GetSupportedPairsResponse = Array<Pair>;

export const getSupportedTokens = async (chainId: number) => {
  if (chainId === 200901) {
    chainId = 200810;
  }
  const { data } = await apiClient.get<GetSupportedPairsResponse>('/v1/tokens/supported-tokens', {
    params: {
      chainId,
    },
  });
  console.log('🚀 ~ getSupportedTokens ~ data:', data);

  return data;
};

export type UseGetSupportedPairsOptions<TData, TError> = QueryOptions<
  GetSupportedPairsResponse,
  TError,
  TData,
  ReturnType<typeof tokenKeys.networkSupportedTokens>
>;

export const useSupportedTokens = <TData = GetSupportedPairsResponse, TError = Error>(
  options?: UseGetSupportedPairsOptions<TData, TError>,
) => {
  const chainId = useChainId();

  return useQuery({
    queryKey: tokenKeys.networkSupportedTokens(chainId),
    queryFn: () => getSupportedTokens(chainId),
    ...options,
  });
};
