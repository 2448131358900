import { ConfigProvider, theme } from 'antd';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import LayoutDefault from './components/01.layout';
import { THEME_MODE } from './interfaces/theme';
import { RainbowProvider } from './libs/rainbow';
import Routes from './routes/Routes';
import store, { useAppSelector } from './store';

const AppRoutes = () => {
  const userTheme = useAppSelector((state: any) => state.theme);

  const darkMode = userTheme === THEME_MODE.DARK;

  return (
    <ConfigProvider
      theme={{
        // 1. Use dark algorithm
        algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          fontFamily: 'Montserrat',
        },
      }}
    >
      <LayoutDefault>
        <Routes />
      </LayoutDefault>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <RainbowProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </RainbowProvider>
    </Provider>
  );
}

export default App;
