import { generateUserQueryKeys } from 'src/libs/react-query';
import { Address } from 'viem';
import { GetLPHistoryParams } from './get-lp-history';

export const userKeys = {
  all: ['user'] as const,

  balances: (walletAddress: Address) => generateUserQueryKeys(walletAddress, 'balance'),
  allBalances: (walletAddress: Address) => [...userKeys.balances(walletAddress), 'all'] as const,
  portfolio: (walletAddress: Address) => generateUserQueryKeys(walletAddress, 'portfolio'),
  allPortfolio: (walletAddress: Address) => [...userKeys.portfolio(walletAddress), 'all'] as const,
  withdrawSchnorrSig: (walletAddress: Address) =>
    generateUserQueryKeys(walletAddress, 'withdrawSchnorrSig'),
  allWithdrawSchnorrSig: (walletAddress: Address) =>
    [...userKeys.withdrawSchnorrSig(walletAddress), 'all'] as const,
  combineSignatures: (walletAddress: Address) =>
    generateUserQueryKeys(walletAddress, 'combineSignatures'),
  allcombineSignatures: (walletAddress: Address) =>
    [...userKeys.combineSignatures(walletAddress), 'all'] as const,

  lpRewards: (walletAddress: Address) => generateUserQueryKeys(walletAddress, 'lpRewards'),
  allLpRewards: (walletAddress: Address) => [...userKeys.lpRewards(walletAddress), 'all'] as const,


  // modify this for lphistory dont use generateuserquery keys, write it like expectedPrice
  lpHistory: () => [...userKeys.all, 'lpHistory'] as const,
  lpHistoryAll: (params: GetLPHistoryParams) =>
    [...userKeys.lpHistory(), params] as const,

 
};
