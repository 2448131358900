import React from 'react';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';
import MenuItem from './MenuItem';
import './styles.scss';

export interface IMenuItem {
  label: string;
  path?: string;
  subMenus?: {
    label: string;
    path: string;
  }[];
}

const SubMenu: React.FC = () => {
  const { pathname } = useLocation();
  console.log('🚀 ~ pathname:', pathname);
  const renderListMenu = () => {
    const listMenu: IMenuItem[] = [
      {
        label: 'Trade',
        path: PATHS.trade(),
      },
      // {
      //   label: 'Swap',
      //   path: PATHS.swap(),
      // },
      {
        label: 'LP',
        path: PATHS.liquidityPool(),
      },
      // {
      //   label: 'Referrals',
      //   path: PATHS.referrals(),
      // },
      // {
      //   label: 'Points',
      //   path: PATHS.points(),
      // },
      // {
      //   label: 'Api',
      //   path: PATHS.api(),
      // },
      {
        label: 'Portfolio',
        path: PATHS.portfolio(),
      },
    ];

    return listMenu.map((itemMap: IMenuItem, i: number) => {
      return (
        <div className="relative">
          <MenuItem key={i} label={itemMap.label} path={itemMap.path} subMenus={itemMap.subMenus} />
          {itemMap.path?.includes(pathname) && (
            <div className="h-0.5 w-full absolute -bottom-5 bg-[#3366FF]"></div>
          )}
        </div>
      );
    });
  };

  return <div className="flex gap-4 xl:gap-10 h-[100%]">{renderListMenu()}</div>;
};

export default React.memo(SubMenu);
