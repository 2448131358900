import { useQueryClient } from '@tanstack/react-query';
import {
  Flex,
  Form,
  InputNumber,
  Modal,
  ModalProps,
  Select,
  Skeleton,
  Space,
  message,
  notification,
} from 'antd';
import BigNumber from 'bignumber.js';
import { useEffect, useMemo } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { delay } from 'src/helpers';
import { convertTimestampToDate } from 'src/helpers/date';
import { decrypt, safeJSONParse } from 'src/helpers/encryption';
import { useVaultAddress } from 'src/hooks/useVaultAddress';
import { maxValidator, positiveNumberValidator } from 'src/libs/validator';
import { handleTransactionError } from 'src/libs/wagmi';
import { useSetCombinePublicKey } from 'src/services/auth/set-combined-public-key';
import { buildApproveVariables, useApprove } from 'src/services/blockchain/approve';
import {
  UseDepositOptions,
  buildDepositVariables,
  useDeposit,
} from 'src/services/blockchain/deposit';
import { useErc20Allowance } from 'src/services/blockchain/get-allowance';
import { useErc20Balance } from 'src/services/blockchain/get-erc20-balance';
import { Token, useSupportedTokens } from 'src/services/token/get-supported-tokens';
import { useFaucet } from 'src/services/user/get-faucet-tokens';
import { useGetPortfolio } from 'src/services/user/get-portfolio';
import { Address, formatUnits, isAddressEqual, parseUnits } from 'viem';
import { useAccount, useChainId, useWaitForTransactionReceipt } from 'wagmi';
import { Button, LoadingButton } from '../../ui/button';
const getActiveToken = (supportedTokens: Token[], activeTokenAddress: Address) => {
  const token = supportedTokens.find(({ address }) => isAddressEqual(address, activeTokenAddress));

  return token;
};

interface FormValues {
  token: Address;
  amount: number;
}

type DepositModalProps = Pick<ModalProps, 'open' | 'onClose'> & {
  onDepositSuccess?: NonNullable<UseDepositOptions<unknown>['mutation']>['onSuccess'];
  onDepositError?: NonNullable<UseDepositOptions<unknown>['mutation']>['onError'];
  onDepositSettle?: () => void;
};

export const DepositModal = ({
  open,
  onClose,
  onDepositError,
  onDepositSuccess,
  onDepositSettle,
}: DepositModalProps) => {
  const queryClient = useQueryClient();

  const { address: userAddress } = useAccount();

  const supportedTokensQuery = useSupportedTokens();
  console.log('🚀 ~ supportedTokensQuery:', supportedTokensQuery.data);

  const [form] = Form.useForm();
  const initialSelectedToken = supportedTokensQuery.data?.at?.(0)?.address;
  const selectedTokenAddress = Form.useWatch('token', form) ?? initialSelectedToken;
  const depositAmount = Form.useWatch('amount', form);
  const chainId = useChainId();
  const erc20BalanceQuery = useErc20Balance({ tokenAddress: selectedTokenAddress });

  const vaultAddress = useVaultAddress();
  const setCombinePublicKeyMutation = useSetCombinePublicKey();
  const { mutateAsync: getTokenFromFaucetCall } = useFaucet();
  const allowanceQuery = useErc20Allowance({
    tokenAddress: selectedTokenAddress,
    contractAddress: vaultAddress,
  });
  const balanceQuery = useGetPortfolio();
  const approveMutation = useApprove();
  const waitForApproveReceiptQuery = useWaitForTransactionReceipt({
    hash: approveMutation.data,
    // confirmations: Number(process.env.REACT_APP_BLOCK_CONFIRMATION!),
    confirmations: 3,

    query: {
      enabled: !!approveMutation.data,
    },
  });

  console.log(
    '🚀 ~ waitForApproveReceiptQuery:',
    waitForApproveReceiptQuery.data,
    waitForApproveReceiptQuery.status,
    waitForApproveReceiptQuery.isSuccess,
    waitForApproveReceiptQuery,
  );
  const refetchAllowance = allowanceQuery.refetch;
  useEffect(() => {
    if (waitForApproveReceiptQuery.status !== 'pending') {
      refetchAllowance();
    }
    if (waitForApproveReceiptQuery.status === 'success') {
      notification.success({
        message: 'Approve Successful',
        description: 'Your approval has been successful',
        placement: 'bottomRight',
      });
    }
  }, [waitForApproveReceiptQuery.status, refetchAllowance]);

  const handleApprove = () => {
    const approve = approveMutation.writeContract(
      buildApproveVariables({
        amount: parseUnits(depositAmount.toString(), 18),
        tokenAddress: selectedTokenAddress,
        vaultAddress,
      }),
      {
        onSuccess: async (data) => {
          console.log('data113', data);
          // const transactionReceipt = waitForTransactionReceipt(config, {
          //   hash: '0x4ca7ee652d57678f26e887c149ab0735f41de37bcad58c9f6d3ed5824f15b74d',
          // });
          // notification.success({
          //   message: 'Approve Successful',
          //   description: 'Your approval has been successful',
          //   placement: 'bottomRight',
          // });
        },
        onError: handleTransactionError(() => {
          message.error('Approve failed');
        }),
      },
    );
    console.log('🚀 ~ handleApprove ~ approve:', approve);
    return approve;
  };

  const getTokens = async () => {
    try {
      const faucetData = await getTokenFromFaucetCall({ chainID: chainId });
      // console.log('🚀 ~ getTokens ~ faucetData:', faucetData);
      //@ts-ignore
      if (faucetData.nextFaucetCall) {
        //@ts-ignore
        const nextCall = convertTimestampToDate(faucetData.nextFaucetCall!);
        notification.error({
          message: 'Something went wrong',
          description: `Your faucet call could not be completed. Please try again at ${nextCall}`,
          placement: 'bottomRight',
        });
      } else {
        notification.success({
          message: 'Faucet call Successful',
          description: 'Your test tokens are on their way!',
          placement: 'bottomRight',
        });

        await new Promise((resolve) => setTimeout(resolve, 10000));

        const refetchCount = 15;
        const refetchInterval = 1000;

        for (let i = 0; i < refetchCount; i++) {
          await new Promise((resolve) => setTimeout(resolve, refetchInterval));
          await refetchErc20Balance();
          // console.log(`Refetched balance (${i + 1}/${refetchCount}/${erc20BalanceQuery.data})`);
        }
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong',
        description: 'Your faucet call could not be completed. Please try again or contact support',
        placement: 'bottomRight',
      });
    }
  };

  const depositMutation = useDeposit();
  const waitForDepositReceiptQuery = useWaitForTransactionReceipt({
    hash: depositMutation.data,
    // confirmations: Number(process.env.REACT_APP_BLOCK_CONFIRMATION!),
    confirmations: 3,
    query: {
      enabled: !!depositMutation.data,
    },
  });

  const refetchErc20Balance = erc20BalanceQuery.refetch;

  useEffect(() => {
    if (waitForDepositReceiptQuery.status === 'pending') {
      return;
    }
    if (waitForDepositReceiptQuery.status === 'success') {
      notification.success({
        message: 'Deposit Successful',
        description: 'Your deposit has been successful',
        placement: 'bottomRight',
      });
      form.setFieldValue('amount', 0);
      balanceQuery.refetch();

      // setTimeout(() => {
      //   queryClient.invalidateQueries({ queryKey: userKeys.allBalances(userAddress!) });
      // }, 10000);

      // setInterval(() => {
      //   queryClient.invalidateQueries({ queryKey: userKeys.allBalances(userAddress!) });
      // }, 1000);

      // queryClient.invalidateQueries({ queryKey: userKeys.allBalances(userAddress!) }),
      // refetchErc20Balance();
    }

    (async () => {
      onDepositSettle?.();

      await delay(2000);
      // notification.success({
      //   message: 'Deposit Successful',
      //   description: 'Your deposit has been successful',
      //   placement: 'bottomRight',
      // });
      const storedData = secureLocalStorage.getItem(`signerData-${userAddress}`) as string;
      const decryptedData = decrypt(storedData, 'vDex', userAddress as string);

      if (decryptedData) {
        const parsedData = safeJSONParse(decryptedData);
        console.log('🚀 ~ parsedData:', parsedData);
        // const userPublicKey = JSON.parse(decryptedData);

        setCombinePublicKeyMutation.mutate({
          chainID: chainId,
          publicKey: parsedData.publicKey,
        });
      }
      await Promise.allSettled([refetchErc20Balance()]);
    })();
  }, [waitForDepositReceiptQuery.status, refetchErc20Balance]);

  const activeToken = useMemo(
    () => getActiveToken(supportedTokensQuery.data ?? [], selectedTokenAddress),
    [supportedTokensQuery.data, selectedTokenAddress],
  );

  let maxDepositSize: number;
  if (erc20BalanceQuery.data === undefined) {
    maxDepositSize = 0;
  } else {
    maxDepositSize = Number(
      formatUnits(erc20BalanceQuery.data ?? BigInt(0), activeToken?.decimal ?? 18),
    );
  }

  const handleSubmit = ({ amount, token }: FormValues) => {
    const parsedAmount = parseUnits(amount.toString(), activeToken?.decimal ?? 18);

    depositMutation.writeContract(
      buildDepositVariables({
        amount: parsedAmount,
        tokenAddress: token,
        vaultAddress,
      }),
      {
        onSuccess: onDepositSuccess,
        onError: onDepositError,
      },
    );
  };

  const renderFooter: ModalProps['footer'] = (_, { CancelBtn, OkBtn }) => {
    if (allowanceQuery.isPending) {
      return (
        <Space>
          <Skeleton.Button active />
          <Skeleton.Button active />
        </Space>
      );
    }

    const _depositAmount = new BigNumber(depositAmount).multipliedBy(
      Math.pow(10, activeToken?.decimal ?? 18),
    );
    const allowanceSufficient = new BigNumber(
      allowanceQuery.data?.toString() ?? 0,
    ).isGreaterThanOrEqualTo(_depositAmount);

    if (allowanceSufficient) {
      return (
        <div className="flex gap-2 items-end justify-end">
          {/* <CancelBtn />
          <OkBtn /> */}
          <Button type="button" onClick={onClose} variant={'outline'} className="text-black">
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            loading={depositMutation.isPending || waitForDepositReceiptQuery.isFetching}
            className="bg-[#00632B] py-4 min-w-[120px] text-white hover:bg-[#00682B]"
          >
            Confirm Deposit
          </LoadingButton>
        </div>
      );
    }

    return (
      <LoadingButton
        loading={
          approveMutation.isPending ||
          waitForApproveReceiptQuery.isFetching ||
          allowanceQuery.isFetching
        }
        className="bg-[#00632B] py-4 min-w-[120px] text-white hover:bg-[#00682B]"
        onClick={handleApprove}
      >
        Approve
      </LoadingButton>
    );
  };

  return (
    <Modal
      title="Deposit"
      className="font-montserrat"
      open={open}
      onClose={(e) => {
        form.setFieldValue('amount', 0);
        onClose!(e);
      }}
      onCancel={(e) => {
        form.setFieldValue('amount', 0);
        onClose!(e);
      }}
      footer={renderFooter}
      confirmLoading={depositMutation.isPending || waitForDepositReceiptQuery.isFetching}
      modalRender={(dom) => (
        <Form
          layout="vertical"
          form={form}
          name="deposit_form"
          initialValues={{
            token: initialSelectedToken,
          }}
          onFinish={handleSubmit}
        >
          {dom}
        </Form>
      )}
      style={{ fontFamily: 'Montserrat' }}
    >
      <Form.Item label="Asset" name="token">
        <Select className="font-montserrat">
          {supportedTokensQuery.data?.map(({ address, token }) => (
            <Select.Option value={address} key={address}>
              {token}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
          {
            type: 'number',
            message: 'Must be a valid number',
          },
          {
            validator: positiveNumberValidator(),
          },
          {
            validator: maxValidator(maxDepositSize),
          },
        ]}
        initialValue={0}
        validateFirst
        label="Amount"
        name="amount"
        className="font-montserrat"
      >
        <InputNumber
          className="font-montserrat"
          addonAfter={
            <Button
              onClick={() => {
                form.setFieldValue('amount', maxDepositSize);
              }}
              variant="link"
              className=" dark:text-white"
            >
              Max
            </Button>
          }
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Flex justify="space-between" style={{ marginBottom: 24 }}>
        <p className="font-montserrat">Available</p>
        <p className="font-montserrat">
          {erc20BalanceQuery.data !== undefined
            ? formatUnits(erc20BalanceQuery.data, activeToken?.decimal ?? 18)
            : null}{' '}
          {activeToken?.token}
        </p>
      </Flex>
      <Flex gap={8} style={{ alignItems: 'center' }}>
        <Button className="text-black" variant={'outline'} onClick={getTokens}>
          Faucet
        </Button>
        {/* <Typography.Link>See deposit history</Typography.Link> */}
      </Flex>
    </Modal>
  );
};
