import { IS_STABLE_COIN } from 'src/constants/exchange';

export const ellipseAddress = (
  address = '',
  maxCharacters = 5,
  maxLastCharacters?: number | undefined,
): string => {
  if (!address) return '';

  return `${address.slice(0, maxCharacters)}...${address.slice(
    -(maxLastCharacters ? maxLastCharacters : maxCharacters),
  )}`;
};

export const decimalCount = (number: any) => {
  const numberAsString = number?.toString();
  if (numberAsString?.includes('.')) {
    return numberAsString?.split('.')?.[1]?.length;
  }
  return 0;
};

export const isStableCoin = (isFNFTOrCoin: any) => {
  return isFNFTOrCoin === IS_STABLE_COIN;
  // return coin?.toUpperCase().includes(STABLE_COINS.USD || STABLE_COINS.USDT || STABLE_COINS.BUSD);
};

export const delay = (timer: number) =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, timer);
  });

export const calculateTotalAmount = (objects: any): number => {
  if (objects.length < 1) {
    return 0;
  }
  const amount = objects.reduce(
    (total: number, obj: any) => total + obj.amount * obj.current_price,
    0,
  );
  return amount;
};
export const formatString = (str: string): string => {
  const startLength = 6;
  const endLength = 6;

  if (str.length <= startLength + endLength) {
    return str;
  }

  const start = str.substring(0, startLength);
  const end = str.substring(str.length - endLength);
  return `${start}...${end}`;
};

export const extractErrorMessage = (log: string): string => {
  // Define a regex pattern to extract the JSON part of the log
  const pattern = /response:\s*(\{.*\})/;

  // Search for the pattern in the log
  const match = log.match(pattern);

  if (match) {
    // Extract the JSON string
    const jsonStr = match[1];
    try {
      // Parse the JSON string
      const response = JSON.parse(jsonStr);
      // Get the error message if available
      const errorMessage = response.error || 'No error message found';
      // Capitalize the first letter of the error message
      return capitalizeFirstLetter(errorMessage);
    } catch (error) {
      return 'Invalid JSON in log';
    }
  } else {
    return 'No JSON response found in log';
  }
};

export const capitalizeFirstLetter = (text: string): string => {
  if (!text) return text;
  return text.charAt(0).toUpperCase() + text.slice(1);
};
export const formatNumber = (value: number, decimalPlaces: number = 4): number => {
  const multiplier = Math.pow(10, decimalPlaces);
  return Math.floor(value * multiplier) / multiplier;
};