import Icon from '@ant-design/icons';
import { ConnectButton, useChainModal } from '@rainbow-me/rainbowkit';
import { notification } from 'antd';
import { MenuItemType } from 'antd/es/menu/interface';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DarkModeIcon, LightModeIcon } from 'src/assets/icons';
import { ArrowRightLanguageIconComponent } from 'src/assets/icons/IconComponent';
import { Button } from 'src/components/ui/button';
import { PATHS } from 'src/constants/paths';
import { useSocket } from 'src/contexts/SocketContext';
import { setStorageTheme } from 'src/helpers/storage';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { THEME_MODE } from 'src/interfaces/theme';
import { chains, useAuth } from 'src/libs/rainbow';
import { useGetBalances } from 'src/services/user/get-balance';
import { setTheme } from 'src/store/actions/theme';
import { useChainId } from 'wagmi';
import { DepositModal } from '../DepositModal';
import AccountContent from './AccountContent';
import './mobile-menu-detail-content.scss';
import WalletContent from './WalletContent';

interface IWalletDetail {
  address: string;
  onClickItem: () => void;
  isOpen: boolean;
}

const MobileMenuDetail: React.FC<IWalletDetail> = ({ address, onClickItem, isOpen }) => {
  const isAuth = false;
  const dispatch = useDispatch();
  const { push } = useHistory();
  const userTheme = useSelector((state: any) => state.theme);
  const account = useSelector((state: any) => state.currentAccount);
  const [isOpenWallet, setIsOpenWallet] = useState(false);
  const [isOpenAccount, setIsOpenAccount] = useState(false);
  const { isUnauthenticated, isAuthenticated } = useAuth();
  const balanceQuery = useGetBalances();
  const chainId = useChainId();
  const { openChainModal } = useChainModal();
  const { userBalance } = useSocket();

  const [depositModalOpen, depositModalActions] = useDisclosure();

  const handleChangeTheme = () => {
    if (userTheme === THEME_MODE.DARK) {
      dispatch(setTheme(THEME_MODE.LIGHT));
      setStorageTheme(THEME_MODE.LIGHT.toLocaleLowerCase());
    } else {
      dispatch(setTheme(THEME_MODE.DARK));
      setStorageTheme(THEME_MODE.DARK.toLocaleLowerCase());
    }
  };

  const handleToggleWalletContent = () => {
    setIsOpenWallet(!isOpenWallet);
  };

  const handleToggleAccountContent = () => {
    setIsOpenAccount(!isOpenAccount);
  };
  const isSupportedNetwork = (): boolean => {
    return Object.values(chains).some((chain) => chain.id === chainId);
  };

  const handleDisconnect = () => {
    setIsOpenAccount(false);
    onClickItem();
  };

  useEffect(() => {
    if (!isOpen) {
      setIsOpenWallet(false);
      setIsOpenAccount(false);
    }
  }, [isOpen]);

  const ListMenuAndSub = useMemo(
    () => [
      {
        label: 'Trade',
        className: 'exchange-icon ',
        key: PATHS.trade(),
      },
      {
        label: 'Portfolio',
        key: PATHS.portfolio(),
        className: 'exchange-icon ',
      },
      // {
      //   label: 'Swap',
      //   className: 'exchange-icon',
      //   key: PATHS.swap(),
      // },
      {
        label: 'LP',
        className: 'exchange-icon',
        key: PATHS.liquidityPool(),
      },
      // {
      //   label: 'Referrals',
      //   className: 'exchange-icon',
      //   key: PATHS.referrals(),
      // },
      // {
      //   label: 'Points',
      //   className: 'exchange-icon',
      //   key: PATHS.points(),
      // },
      // {
      //   label: 'Apis',
      //   className: 'exchange-icon',
      //   key: PATHS.api(),
      // },
    ],
    [],
  );

  const renderMenus = useMemo<MenuItemType[]>(() => {
    return ListMenuAndSub.map((item) => {
      return {
        label: item.label,
        key: item.key,
        className: item.className,
      };
    });
  }, [ListMenuAndSub]);

  const handleDepositModal = () => {
    if (isUnauthenticated) {
      notification.info({
        message: 'Wallet Connection',
        description: 'Please connect your wallet first',
        placement: 'bottomRight',
        style: { fontFamily: 'Montserrat' },
      });
    } else {
      depositModalActions.open();
    }
  };

  if (isOpenWallet) {
    return (
      <WalletContent
        address={address}
        onClose={handleToggleWalletContent}
        onClickItem={() => {
          onClickItem();
          setIsOpenWallet(false);
        }}
      />
    );
  }

  if (isOpenAccount) {
    return <AccountContent onClose={handleToggleAccountContent} onDisconnect={handleDisconnect} />;
  }

  return (
    <div className={`mobile-menu-detail dark:bg-dark-1 dark:text-white`}>
      <div className="dark:bg-dark-1 dark:text-white mt-2">
        <div className="flex items-center dark:bg-dark-1 justify-center">
          {/* <div>
            <span>{'Wallet'}</span>
          </div> */}

          <div className="flex justify-center items-center gap-2">
            <Button
              className="bg-[#00632B] py-4 min-w-[120px] text-white hover:bg-[#00682B]"
              onClick={handleDepositModal}
            >
              Deposit
            </Button>
            {/* {isSupportedNetwork() ? ( */}
            <ConnectButton showBalance={false} />

            {/* ) : ( */}
            {/* <Button onClick={openChainModal}>Wrong Network</Button> */}
            {/* )} */}
            <h3 className="text-black dark:text-white">
              {isAuthenticated && userBalance > 0 ? userBalance.toLocaleString() : 0} USD
            </h3>
          </div>
        </div>
      </div>
      <div className="line" style={{ marginBottom: 10 }}></div>
      {/* <Menu
        mode="inline"
        items={renderMenus}
        className="dark:text-white"

      /> */}
      <div className="flex px-3.5 flex-col gap-2">
        {ListMenuAndSub.map((item) => {
          return (
            <a
              onClick={() => {
                push(item.key);
                onClickItem();
              }}
              key={item.key}
              className={
                'hover:dark:text-gray-400 text-black dark:text-white hover:text-gray-600 py-2.5 px-2 text-base hover:dark:bg-gray-500 hover:bg-gray-300 rounded'
              }
            >
              {item.label}
            </a>
          );
        })}
      </div>
      <div className="line"></div>

      <div className="mobile-menu-detail__item-wrapper mb-26">
        <div className="mobile-menu-detail__item-wrapper__item  justify-between">
          <div>
            <span>{'Appearance'}</span>
          </div>
          <div className="flex gap-1.5 dark:text-white">
            <span className="dark:text-white">
              {userTheme === THEME_MODE.DARK ? 'Dark' : 'Light'}
            </span>
            <img
              src={userTheme === THEME_MODE.DARK ? DarkModeIcon : LightModeIcon}
              alt="icon"
              onClick={handleChangeTheme}
            />
          </div>
        </div>
      </div>

      <div className="line"></div>

      {/* <div className="mobile-menu-detail__item-wrapper">
        <div className="mobile-menu-detail__item-wrapper__item">
          <div className={`network ${networkIsWrong ? 'warning-network' : ''}`}>
            <img src={NetworkIcon} alt="icon" />
            <span>{networkIsWrong ? 'Wrong Network' : ' BSC'}</span>
          </div>
        </div>
      </div> */}

      {isAuth && account && (
        <>
          <div className="mobile-menu-detail__item-wrapper">
            <div
              className="mobile-menu-detail__item-wrapper__item justify-between"
              onClick={handleToggleWalletContent}
            >
              <div>
                <span>{'Wallet'}</span>
              </div>
              <Icon className="" component={ArrowRightLanguageIconComponent} />
            </div>
          </div>
        </>
      )}

      <DepositModal
        key={2}
        open={depositModalOpen}
        onClose={depositModalActions.close}
        onDepositSettle={depositModalActions.close}
      />
    </div>
  );
};

export default MobileMenuDetail;
