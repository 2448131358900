import { ObjectValues } from 'src/types/utils';

export const ORDER_PATH_PARAMS = {
  ALL: 'all',
  OPEN: 'open',
  NATIVE: ':type',
} as const;

export type OrderTypes = ObjectValues<typeof ORDER_PATH_PARAMS>;

export interface TradePageParams {
  pair?: string;
}

export const PATHS = {
  default: () => '/',
  walletAnalytics: () => '/wallet-analytics',
  trade: ({ pair }: TradePageParams = {}) => {
    if (pair === undefined) {
      return '/trade/:pair?';
    }

    return `/trade/${pair}`;
  },
  swap: () => '/swap',
  portfolio: () => '/portfolio',
  liquidityPool: () => '/liquidity-pool',
  settings: () => '/settings',
  referrals: () => '/referrals',
  points: () => '/points',
  api: () => '/market-api',
  orders: (type: OrderTypes = ORDER_PATH_PARAMS.NATIVE) => `/orders/${type}`,
};
